exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admon-pedidos-js": () => import("./../../../src/pages/admon-pedidos.js" /* webpackChunkName: "component---src-pages-admon-pedidos-js" */),
  "component---src-pages-admon-productos-js": () => import("./../../../src/pages/admon-productos.js" /* webpackChunkName: "component---src-pages-admon-productos-js" */),
  "component---src-pages-admon-tiendas-js": () => import("./../../../src/pages/admon-tiendas.js" /* webpackChunkName: "component---src-pages-admon-tiendas-js" */),
  "component---src-pages-admon-users-js": () => import("./../../../src/pages/admon-users.js" /* webpackChunkName: "component---src-pages-admon-users-js" */),
  "component---src-pages-admon-usuarios-js": () => import("./../../../src/pages/admon-usuarios.js" /* webpackChunkName: "component---src-pages-admon-usuarios-js" */),
  "component---src-pages-admon-usuarios-old-js": () => import("./../../../src/pages/admon-usuarios-old.js" /* webpackChunkName: "component---src-pages-admon-usuarios-old-js" */),
  "component---src-pages-alta-negocio-js": () => import("./../../../src/pages/alta-negocio.js" /* webpackChunkName: "component---src-pages-alta-negocio-js" */),
  "component---src-pages-alta-negocio-old-js": () => import("./../../../src/pages/alta-negocio-old.js" /* webpackChunkName: "component---src-pages-alta-negocio-old-js" */),
  "component---src-pages-alta-restaurante-js": () => import("./../../../src/pages/alta-restaurante.js" /* webpackChunkName: "component---src-pages-alta-restaurante-js" */),
  "component---src-pages-canasta-compra-js": () => import("./../../../src/pages/canasta-compra.js" /* webpackChunkName: "component---src-pages-canasta-compra-js" */),
  "component---src-pages-cotizacion-pedido-uuid-js": () => import("./../../../src/pages/cotizacion/[pedido_uuid].js" /* webpackChunkName: "component---src-pages-cotizacion-pedido-uuid-js" */),
  "component---src-pages-dev-firebase-test-js": () => import("./../../../src/pages/dev/firebase-test.js" /* webpackChunkName: "component---src-pages-dev-firebase-test-js" */),
  "component---src-pages-dev-test-pages-js": () => import("./../../../src/pages/dev/test-pages.js" /* webpackChunkName: "component---src-pages-dev-test-pages-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mi-cuenta-js": () => import("./../../../src/pages/mi-cuenta.js" /* webpackChunkName: "component---src-pages-mi-cuenta-js" */),
  "component---src-pages-nego-afiliado-slug-admin-js": () => import("./../../../src/pages/nego/[afiliado_slug]/admin.js" /* webpackChunkName: "component---src-pages-nego-afiliado-slug-admin-js" */),
  "component---src-pages-nego-afiliado-slug-admin-menu-js": () => import("./../../../src/pages/nego/[afiliado_slug]/admin-menu.js" /* webpackChunkName: "component---src-pages-nego-afiliado-slug-admin-menu-js" */),
  "component---src-pages-nego-afiliado-slug-canasta-compra-js": () => import("./../../../src/pages/nego/[afiliado_slug]/canasta-compra.js" /* webpackChunkName: "component---src-pages-nego-afiliado-slug-canasta-compra-js" */),
  "component---src-pages-nego-afiliado-slug-combos-js": () => import("./../../../src/pages/nego/[afiliado_slug]/combos.js" /* webpackChunkName: "component---src-pages-nego-afiliado-slug-combos-js" */),
  "component---src-pages-nego-afiliado-slug-cotizacion-pedido-uuid-js": () => import("./../../../src/pages/nego/[afiliado_slug]/cotizacion/[pedido_uuid].js" /* webpackChunkName: "component---src-pages-nego-afiliado-slug-cotizacion-pedido-uuid-js" */),
  "component---src-pages-nego-afiliado-slug-cotizador-js": () => import("./../../../src/pages/nego/[afiliado_slug]/cotizador.js" /* webpackChunkName: "component---src-pages-nego-afiliado-slug-cotizador-js" */),
  "component---src-pages-nego-afiliado-slug-menu-js": () => import("./../../../src/pages/nego/[afiliado_slug]/menu.js" /* webpackChunkName: "component---src-pages-nego-afiliado-slug-menu-js" */),
  "component---src-pages-nego-afiliado-slug-tienda-js": () => import("./../../../src/pages/nego/[afiliado_slug]/tienda.js" /* webpackChunkName: "component---src-pages-nego-afiliado-slug-tienda-js" */),
  "component---src-pages-nego-nego-slug-js": () => import("./../../../src/pages/nego/[nego_slug].js" /* webpackChunkName: "component---src-pages-nego-nego-slug-js" */),
  "component---src-pages-pedidos-all-js": () => import("./../../../src/pages/pedidos-all.js" /* webpackChunkName: "component---src-pages-pedidos-all-js" */),
  "component---src-pages-registro-js": () => import("./../../../src/pages/registro.js" /* webpackChunkName: "component---src-pages-registro-js" */)
}

